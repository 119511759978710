/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* General styles for the entire app */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header styles */
.header {
  padding: 20px 0;
  text-align: center;
}

/* News feed styles */
.news-item {
  border-bottom: 1px solid #333333;
  padding: 20px 0;
}

.news-item:last-child {
  border-bottom: none;
}

.news-item h3 {
  margin: 0;
  font-size: 24px;
}

.news-item img {
  max-width: 100%;
  height: auto;
}

/* Like & Bookmark buttons */
.button {
  padding: 10px 20px;
  margin: 10px;
  background-color: #6200ea;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Search bar styles */
.search-bar {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.search-input {
  padding: 10px;
  flex: 1;
  border: none;
  border-radius: 4px 0 0 4px;
}

.search-button {
  padding: 10px 20px;
  background-color: #6200ea;
  color: #ffffff;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

/* Grey out viewed news items */
.viewed {
  opacity: 0.5;
}

/* Responsive layout */
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .news-item h3 {
    font-size: 20px;
  }
}

/* General styles */
.container {
  font-family: Arial, sans-serif;
  background-color: #121212;
  color: #ffffff;
  padding: 20px;
}

/* News Feed styles */
.news-item {
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 4px;
}

.news-item img {
  max-width: 120px;
  margin-right: 10px;
}

.news-item-content {
  flex: 1;
}

.news-item-summary {
  margin-bottom: 10px;
}

.news-item-actions {
  display: flex;
  justify-content: space-between;
}

/* News Item Detail styles */
.news-item-detail {
  padding: 20px;
  border: 1px solid #333;
  border-radius: 4px;
}

.news-item-detail img {
  max-width: 100%;
  margin-bottom: 20px;
}

.news-item-detail h1 {
  margin-bottom: 10px;
}

/* Like & Bookmark Buttons styles */
.button {
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.button.like {
  background-color: #e74c3c;
  color: #ffffff;
}

.button.bookmark {
  background-color: #3498db;
  color: #ffffff;
}

.button.icon {
  margin-right: 5px;
}

/* Responsive */
@media screen and (max-width: 768px) {
  .news-item img {
    max-width: 80px;
  }
}

.newsItem-link {
  text-decoration: none;
  color: inherit;
}

.newsItem-content {
  flex: 1;
}

.newsItem-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
}

.newsItem-stats {
  font-size: 14px;
}

/* .newsItem-likes,
.newsItem-bookmarks {
  margin-right: 10px;
} */
/* 
.newsItem-like-button {
  color: #e74c3c;
  margin-right: 10px;
} */

.newsItem-bookmark-button {
  color: #3498db;
}

/* Apply dark mode theme */
/* body {
  background-color: #121212;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
} */

/* Styling for the news item detail page header */
.news-item-detail-header {
  display: flex;
  align-items: center;
}

/* Styling for the back button */
.back-button {
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

/* Styling for the tabs */
.react-tabs__tab-list {
  border-bottom: 1px solid #444;
  padding-left: 0;
  padding: 15px;
}

.react-tabs__tab {
  list-style: none;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #222;
  border: none;
  margin-right: 5px;
  color: #fff;
}

/* Active tab style */
.react-tabs__tab--selected {
  background-color: #444;
  border-bottom: 3px solid #FF6347;
  /* highlight color */
}

/* Styling for the tab panels */
.react-tabs__tab-panel {
  padding: 20px;
}

/* News image inside tab panel */
.news-item-detail img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

/* Placeholder comments styling */
.news-item-detail .comments-placeholder {
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  color: #aaa;
}

.newsItem {
  background-color: #2a2a2a;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.newsItem-link {
  text-decoration: none;
  color: inherit;
}

.newsItem-header {
  display: flex;
  /* Use flexbox to make the elements inline */
  align-items: flex-start;
  /* Align items to the top */
}

.newsItem-image {
  width: 40px;
  /* height: 40px; */
  margin-right: 10px;
  /* object-fit: cover; */
  /* display: none; */
}

.newsItem-content {
  flex: 1;
}

.newsItem-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
}

.newsItem-stats {
  font-size: 14px;
}

/* .newsItem-likes,
.newsItem-bookmarks {
  margin-right: 10px;
} */

.newsItem-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.newsItem-like-button {
  color: #e74c3c;
  /* margin-right: 10px; */
}

.newsItem-bookmark-button {
  color: #3498db;
}

.newsItem-comment-button {
  color: #eceff1;
}

span.newsItem-tags-span {
  padding: 4px 9px;
  font-size: 0.8em;
  margin-right: 6px;
  margin-bottom: 4px;
  border-radius: 2px;
}

/* Add media queries for mobile responsiveness */

@media screen and (max-width: 768px) {

  /* .newsItem-header {
    flex-direction: column; 
  } */

  /* .newsItem-image {
    width: 100%;
    height: auto;
    margin-right: 0;
    display: block;
    padding-bottom: 15px;
  } */
  span.newsItem-tags-span {
    padding: 1.5px 3px;
    font-size: 0.2em;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 2px;
  }
}

.newsItem-title {
  font-weight: bold;
  font-size: 100%;
  margin: 0;
}

/* Add this to your existing CSS */

.app {
  padding-top: 60px;
  /* Provide space for the fixed top bar */
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #2a2a2a;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.top-bar-title {
  flex: 1;
  color: #FFF;
  margin: 0;
}

.top-bar-navigation {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #FFF;
  text-decoration: none;
  margin-left: 20px;
}

.content-wrapper {
  padding: 20px;
}

.newsItem-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.newsItem-tag {
  background-color: #3498db;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 65%;
}

.search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #333;
}

/* Dropdown categories container */
.category-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.search-term-container {
  display: flex;
}

/* Dropdown select */
.category-select {
  padding: 10px;
  height: 2.8em;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #333;
  outline: none;
  width: 30%;
  /* You can adjust this as needed */
}

/* .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  width: 75%;
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 10000;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .left-sidebar .overlay {
  left: 0;
  transform: translateX(-100%);
} */

.left-sidebar .overlay.open {
  transform: translateX(0);
}

.newsItem-actions {
  display: flex; /* Use flexbox to create a row layout */
  justify-content: space-between; /* Space items evenly across the container */
}

.newsItem-action-buttons {
  display: flex; /* Use flexbox to create a row layout for action buttons */
}

.newsItem-timestamp {
  justify-self: flex-start; /* Justify timestamp to the beginning of the row */
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.category-list a {
  cursor: pointer;
  padding: 10px;
  /* border-bottom: 1px solid #ccc; */
  text-decoration: none;
  color: #eceff1;
}

.category-list li:hover {
  background-color: #f5f5f5;
  color: #121212;
}
.category-list a:hover {
  background-color: #f5f5f5;
  color: #121212;
}
.category-list li a:hover {
  background-color: #f5f5f5;
  color: #121212;
}