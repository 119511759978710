.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1f1f1f;
  color: #fff;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
}

.auth-input {
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  background-color: #2f2f2f;
  color: #fff;
}

.auth-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-btn:hover {
  background-color: #0056b3;
}
.auth-link {
  color: #888;
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;
}

.auth-link:hover {
  color: #ccc;
}